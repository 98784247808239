/* You can add global styles to this file, and also import other style files */

@import "scss/custom-variable";
@import "~bootstrap/scss/bootstrap";
@import "~ngx-bootstrap/datepicker/bs-datepicker";
@import "~@ctrl/ngx-emoji-mart/picker";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.cdk-overlay-container {
  z-index: 999999 !important
}

html,
body {
  height: 100%;
}

.container,
.container-fluid {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: $PoppinsRegular !important;
  font-size: $detail_txt;
  color: $black;
  background: $form_control_bg !important;
  background-color: $form_control_bg !important;
  position: relative;
}

.blur_effect {
  filter: blur(3px) !important;
  pointer-events: none;

  p {
    background-color: white !important;
  }
}

.player {

  // background-color: $black;
  // height: 100vh;
  video {
    position: inherit !important;
    width: auto !important;
    height: 100vh !important;
    margin: auto !important;
    text-align: center !important;
    object-fit: cover !important;
    object-position: center center !important;
    display: block;
  }
}

// video[poster] {
// 	object-fit: fill !important;
// }

.shareico {
  height: 15px;
  width: 15px;
  position: absolute;
  cursor: pointer;
  right: 20px;
}

.accordion-body li {
  color: $welcome_txt_color;
  font-size: $search_txt;
  font-family: $PoppinsRegular;
  margin-bottom: 5px;
  list-style: disc !important;
  margin-left: 15px;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

p {
  padding: 0;
  margin: 0;
}

button {
  border: none;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.form-control {
  border: 1px solid $form_control_border;
  border-radius: 4px;
  background-color: $form_control_bg;
  font-size: $detail_txt;
  font-family: $PoppinsRegular;
  color: $black;
  padding: 8px 15px;
  height: auto;

  &::placeholder {
    color: $form_control_placeholder;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.tab_section {
  ul {
    border: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    li {
      width: 48%;

      a {
        border: 1px solid $checkbox_border !important;
        border-radius: 4px;
        color: $black !important;
        font-size: $detail_txt;
        font-family: $PoppinsRegular;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        &.active {
          background: $btn_bg !important;
          border: transparent !important;
          color: $white !important;
        }
      }
    }
  }
}

.image-cropper {
  height: 300px !important;
}

.image-cropper {
  height: 300px !important;
}

.modal-backdrop.show {
  opacity: 0.75;
  width: 100%;
  height: 100%;
}

.modal-dialog {
  position: unset !important;
  height: 100% !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  @media (max-width: 575px) {
    height: auto;
    margin: 65px 0.5rem 0.5rem;
  }

  &.modal-fullscreen {
    .modal-content {
      height: 100%;
      background-color: #f5f5f5 !important;
    }
  }

  &.extra-small-modal {
    .modal-content {
      max-width: 500px;
    }
  }

  &.barcode-modal {
    .modal-content {
      max-width: 300px;
    }
  }

  &.small-modal {
    padding: 0 30px;
    width: 100%;
    margin: 0;

    .modal-content {
      max-width: 600px;

      @media (max-width: 767px) {
        margin-top: 65px;
        margin-bottom: 30px;
      }
    }
  }

  &.medium-modal {
    display: inline-block;
    padding: 0 30px;
    width: 100%;
    margin: 0;

    .modal-content {
      max-width: 780px;
      margin: 130px auto 0;

      @media (max-width: 676px) {
        margin-top: 70px;
      }
    }
  }

  .modal-content {
    position: unset;
    border-radius: 15px;

    .btn-close {
      position: absolute;
      right: 15px;
      top: 15px;
      width: 60px;
      height: 60px;
      opacity: 1;

      @media (max-width: 575px) {
        width: 45px;
        height: 45px;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  margin-left: 10px;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.body_middle_content_Section {
  height: 100%;
  padding-top: 15px;
}

.gray_border_btn {
  background-color: transparent;
  border: 1px solid $welcome_txt_color;
  border-radius: 4px;
  color: $welcome_txt_color;
  font-size: $detail_txt;
  font-family: $RubikMedium;
}

.orange_fill_btn {
  background: $fill_btn_bg;
  border-radius: 4px;
  border: none;
  color: $white;
  font-size: $search_txt;
  font-family: $RubikMedium;
  padding: 5px 15px;
}

.orange_light_bg {
  background-color: $light_fill_bg !important;
  border-radius: 4px !important;
  border: none !important;
  color: $welcome_txt_red !important;
  font-size: $search_txt !important;
  font-family: $RubikMedium !important;
  padding: 5px 10px !important;
}

.following_btn {
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: $following_btn_bg;
  font-size: $search_txt;
  font-family: $RubikMedium;
  padding: 5px 10px;
}

.following_btn_color {
  color: $following_btn_bg;
}

.orange_border_bg {
  border: 1px solid $welcome_txt_red !important;
  background-color: transparent !important;
  font-size: $btn_txt !important;
  font-family: $RubikMedium !important;
  color: $following_btn_bg !important;
  padding: 5px 10px !important;
  border-radius: 4px !important;
  background: transparent !important;
}

.middle_right_section {
  .box_wraper {
    box-shadow: 0px 2px 10px $sidebar_shadow_color;
    background-color: $white;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
  }

  .ttl_Section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    label {
      color: $black;
      font-size: $detail_txt;
      font-family: $RubikMedium;
      margin: 0;
    }

    a {
      color: $link_color;
      font-size: $detail_txt;
      font-family: $PoppinsMedium;
      cursor: pointer;
    }
  }
}

.disable-button {
  // background: #757575 !important;
  pointer-events: none;
}

.middle_left_section {
  .box_wraper {
    box-shadow: 0px 2px 10px $sidebar_shadow_color;
    background-color: $white;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
  }
}

.switch_section {
  label {
    color: $black;
    font-size: $detail_txt;
    font-family: $RubikMedium;
    margin: 0;
    margin-right: 10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 16px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $form_control_border;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: $user_active_status;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

.plan_list {
  ul {
    justify-content: flex-start !important;
    min-width: auto !important;
  }
}

.profile_tab_section {
  display: inline-block;

  ul {
    min-width: 750px;
    min-height: 50px;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      min-width: auto;
      min-height: auto;
    }

    li {
      width: auto;

      @media (max-width: 767px) {
        margin-bottom: 15px;
      }

      &:last-child {
        a {
          @media (max-width: 1024px) {
            margin-right: 0px;
          }
        }
      }

      a {
        color: $form_control_placeholder !important;
        font-size: $detail_txt !important;
        font-family: $PoppinsSemiBold !important;
        border: none !important;
        padding: 0px 8px 8px 8px !important;
        margin: 0 8px;
        position: relative;
        line-height: normal;
        min-height: 50px;

        @media (max-width: 1024px) {
          padding: 0px 0px 8px 0px !important;
          margin: 0 7px;
        }

        &.active {
          color: $black !important;
          background: transparent !important;
          height: auto;

          &::after {
            content: "";
            height: 2px;
            width: 100%;
            background: $tab_active_bg;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }
}

.signUp_tab_Section {
  .tab-content {

    // max-height: 270px;
    // overflow-y: auto;
    // overflow-x: hidden;
    // scrollbar-width: none; /* Firefox */
    // -ms-overflow-style: none; /* IE 10+ */
    // &::-webkit-scrollbar {
    // 	background: transparent; /* Chrome/Safari/Webkit */
    // 	width: 0px;
    // }
    @media (max-width: 1024px) {
      max-height: 230px;
    }

    @media (max-width: 768px) {
      max-height: unset;
    }
  }
}

.custom_drop_section {
  .btn-group {
    width: 100%;

    button {
      background-color: $form_control_bg !important;
      border: 1px solid $form_control_border !important;
      font-size: 12px;
      font-family: "PoppinsRegular";
      color: $black !important;
      border-radius: 5px;
      height: 38px;
      padding: 10px 35px 10px 15px;
      text-align: left;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        right: 12px;
        top: 11px;
        border: solid $form_control_placeholder;
        border-width: 0 1.5px 1.5px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    .dropdown-menu.show {
      width: 100%;

      li {
        a {
          font-size: 12px;
          font-family: "PoppinsRegular";
          color: $black !important;

          &:hover {
            background-color: $form_control_bg;
          }
        }
      }
    }
  }
}

.custom_radio_btn {
  .radio_container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 0px;
    cursor: pointer;
    color: $radio_txt;
    font-size: $btn_txt;
    font-family: $PoppinsSemiBold;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    img {
      height: 18px;
      object-fit: contain;
      object-position: center;
    }
  }

  .radio_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid $checkbox_border;
  }

  .radio_container input:checked~.checkmark {
    background-color: $white;
    border: 1px solid $welcome_txt_red;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio_container input:checked~.checkmark:after {
    display: block;
  }

  .radio_container .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $welcome_txt_red;
  }
}

.main_screen_ttl {
  color: $black;
  font-size: $event_date_txt;
  font-family: $RubikBold;
  padding-bottom: 10px;
  margin-top: 10px;

  img {
    cursor: pointer;
    margin-right: 8px;
    width: 7px;
    height: 14px;
    object-fit: contain;
    object-position: center;
  }
}

.input-warper {
  ng-otp-input {
    input {
      background-color: $form_control_bg !important;
      border: 1px solid $form_control_border !important;
      font-size: $detail_txt !important;
      font-family: $PoppinsRegular !important;
      color: $black !important;
      border-radius: 5px !important;
      width: 48px !important;
      height: 48px !important;
      padding: 8px 12px !important;

      &::placeholder {
        color: $form_control_placeholder !important;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.resend_txt {
  a {
    color: $black !important;
  }
}

.checkbox_container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0px;
  cursor: pointer;
  color: $black;
  font-size: $detail_txt;
  font-family: $PoppinsRegular;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border: 1px solid $checkbox_border;
  border-radius: 4px;
}

.checkbox_container input:checked~.checkmark {
  background-color: $welcome_txt_red;
  border: none;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox_container input:checked~.checkmark:after {
  display: block;
}

.checkbox_container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.transaction_tab_section {
  margin-top: 15px;
  width: 100%;

  ul {
    margin-bottom: 10px;

    li {
      a {
        margin: 0 !important;
        padding: 0px 20px 8px 20px !important;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.bg-gymfans {
  bar {
    background-color: $inprogress_status !important;
  }
}

.header_search_category_slider {
  border-bottom: 1px solid $date_bg;
  padding-bottom: 10px;
  margin-bottom: 5px;

  .slick-slider {
    .slick-arrow {
      width: 5px;
      height: 11px;
      top: 20px;

      &::before {
        display: none;
      }
    }

    .slick-prev {
      background-image: url("assets/img/slider_right.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(180deg);
      left: 0;
      top: 15px;
    }

    .slick-next {
      background-image: url("assets/img/slider_right.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      right: 0;
    }

    .slick-list {
      width: 94%;
      margin: 0 auto;

      .slide {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        a {
          display: flex;
          flex-direction: column;
        }

        img {
          width: 35px;
          height: 35px;
          object-position: center;
          object-fit: contain;
          margin-bottom: 5px;
          cursor: pointer;
        }

        label {
          margin: 0;
          font-size: $msg_count_txt;
          font-family: $PoppinsMedium;
          color: $welcome_txt_color;
          word-break: break-word;
        }
      }
    }
  }
}

.user_detail_tab_section {
  margin-top: 0px;

  ul {
    margin-bottom: 10px;

    li {
      a {
        margin: 0 25px 0 0 !important;
        padding: 0px 0px 8px 0px !important;
      }
    }
  }
}

.custom_calender_section {
  .c-calendar {
    .c-calendar-month-1 {
      box-shadow: none !important;
      border-radius: 0 !important;
      background-color: transparent !important;
      color: $black !important;
      font-size: $user_txt !important;
      font-family: $PoppinsRegular !important;
      margin: 0 !important;
      display: block !important;
      position: relative !important;
      padding: 0 !important;

      .c-calendar-middle {
        width: calc(100% - 50px);
        margin: 0 !important;

        .c-calendar-month-header {
          text-align: left !important;
          color: $black !important;
          font-size: $btn_txt !important;
          font-family: $PoppinsRegular !important;
          text-transform: unset !important;
          padding: 0 10px;
        }
      }

      .c-calendar-left {
        position: absolute;
        right: 35px;
        top: -1px;
      }

      .c-calendar-right {
        position: absolute;
        right: 0;
        top: -2px;
      }
    }

    .c-calendar-month-2 {
      box-shadow: none !important;
      padding: 0 !important;
      border-radius: 0 !important;
      margin-top: 25px;

      .c-calendar-month-header {
        border: none !important;
        padding: 0 !important;

        .c-calendar-month-date {
          color: $black !important;
          font-family: $PoppinsRegular !important;
          font-size: $btn_txt !important;
        }
      }

      .c-calendar-month-body {
        .c-calendar-month-item {
          margin-top: 12px !important;

          &:hover {
            .c-calendar-month-column {
              background: $msg_count_bg !important;
              border-radius: 25px;
              color: $white !important;
              padding: 5px 9px;
              border: none !important;
            }
          }

          .c-calendar-month-column {
            color: $black !important;
            font-family: $PoppinsRegular !important;
            font-size: $btn_txt !important;
            position: relative;

            &.c-calendar-month-column-active {
              border: none !important;
              background: $msg_count_bg !important;
              border-radius: 25px;
              color: $white !important;
              padding: 5px 9px;
            }
          }
        }
      }
    }
  }
}

.custom_time_Section {
  table {
    tr {
      td {
        &:first-child {
          input {
            width: 50px !important;
            text-align: left !important;
          }
        }

        input {
          border: none;
          background-color: transparent;
          padding: 0;
          text-align: center !important;
          width: 50px;
          color: $black;
          font-size: $btn_txt;
          font-family: $PoppinsRegular;
          height: 34px;

          &::placeholder {
            color: $form_control_placeholder;
          }
        }
      }
    }
  }
}

audio {
  &:focus {
    outline: none;
  }
}

.post_detail_full_modal {
  .modal-content {
    background-color: transparent !important;
    border-radius: 0;
    height: 100%;
    border: none;
    display: block;

    .full_post_carousel_section {
      width: 80%;
      height: 80%;

      @media (max-width: 767px) {
        width: 100%;
        height: 100%;
      }

      .carousel {
        width: 100%;
        height: 100%;
      }

      .carousel-control-prev {
        opacity: 1;
        height: 90%;

        .icon-prev {
          background-image: url("assets/img/carousel_left.svg");
          background-position: center;
          background-repeat: no-repeat;
          width: 45px;
          height: 45px;
        }
      }

      .carousel-control-next {
        opacity: 1;
        height: 90%;

        .icon-next {
          background-image: url("assets/img/carousel_right.svg");
          background-position: center;
          background-repeat: no-repeat;
          width: 45px;
          height: 45px;
        }
      }

      .carousel-indicators {
        display: none;
      }

      .carousel-inner {
        width: 100%;
        height: 100%;

        slide {
          width: 100%;
          height: 100%;

          .item {
            width: 100%;
            height: 100%;
          }
        }

        .img_box {
          width: 100%;
          height: 90%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}

.comment-section {
  max-height: 200px !important;
  overflow-y: auto !important;
}

.bookmark_tab {
  ul {
    li {
      a {
        margin: 0;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}

.pagination_Section {
  ul {
    &.pagination {
      li {
        &.active {
          a {
            color: $white !important;
            // background-color: $welcome_txt_red;
            background: $btn_bg;
          }
        }

        a {
          color: $form_control_placeholder !important;
          font-size: $detail_txt !important;
          font-family: $RubikMedium !important;
          padding: 5px !important;
          margin-right: 5px !important;
          line-height: normal;
          min-width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.like_count_txt {
  cursor: pointer;
}

.custom_calender_view {
  bs-datepicker-inline {
    bs-datepicker-inline-container {
      width: 100%;

      .bs-datepicker {
        box-shadow: none;

        .bs-datepicker-container {
          padding: 0;
          width: 100%;

          bs-days-calendar-view {
            width: 100%;

            .bs-datepicker-head {
              background-color: transparent;
              padding: 0 15px;
              height: 35px;
              margin-bottom: 15px;

              bs-datepicker-navigation-view {
                display: flex;
                align-items: center;
                position: relative;

                .next {
                  position: absolute;
                  right: 0px;
                  top: 8px;
                  width: 12px;
                  height: 12px;
                  background-color: transparent;

                  span {
                    background-image: url("assets/img/cal_right.svg");
                    font-size: 0;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    line-height: unset;
                  }
                }

                .previous {
                  position: absolute;
                  right: 40px;
                  top: 8px;
                  width: 12px;
                  height: 12px;
                  background-color: transparent;

                  span {
                    background-image: url("assets/img/cal_left.svg");
                    font-size: 0;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    line-height: unset;
                  }
                }

                .current {
                  padding: 0;
                  color: $black;
                  font-size: $user_txt;
                  font-family: $PoppinsRegular;
                  margin-right: 15px;
                }
              }
            }

            .bs-datepicker-body {
              border: none;
              padding: 0;

              table {
                tr {
                  th {
                    color: $black;
                    font-size: $btn_txt;
                    font-family: $PoppinsRegular;
                    padding-bottom: 15px;
                  }

                  td {
                    span {
                      color: $black;
                      font-size: $btn_txt;
                      font-family: $PoppinsRegular;

                      &.disabled {
                        color: $cal_light_color;
                      }

                      &.selected {
                        background: $btn_bg;
                        background-color: transparent;
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.swal2-title {
  font-size: 15px !important;
  font-weight: 600;
}

.swal2-styled.swal2-confirm {
  background: $fill_btn_bg !important;
  border-radius: 4px !important;
  border: none !important;
  color: $white !important;
  font-size: 10px !important;
  font-family: "RubikMedium" !important;
  padding: 5px 15px !important;
  width: 150px !important;
  // color: $white !important;
  font-size: 14px !important;
  // font-family: "RubikMedium" !important;
  // border-radius: 6px !important;
  height: 40px !important;
  // border: none !important;
  // display: inline;
  // background: linear-gradient(210deg, #ffb200 0%, $welcome_txt_red 100%) !important;
}

.swal2-styled {
  padding: 0 !important;
}

.swal2-styled.swal2-cancel {
  border: 1px solid $welcome_txt_red !important;
  background-color: transparent !important;
  font-size: 14px !important;
  font-family: "RubikMedium" !important;
  color: $following_btn_bg !important;
  padding: 5px 10px !important;
  border-radius: 4px !important;
  background: transparent !important;
  height: 40px !important;
  width: 150px !important;
}

bs-datepicker-container {
  .bs-datepicker {
    .bs-datepicker-head {
      background-color: transparent;
      background: $btn_bg;
    }

    table {
      tr {
        th {
          padding: 0 5px 10px;
        }

        td {
          color: $black;

          &.week {
            span {
              color: $welcome_txt_red !important;
            }
          }

          span {
            &.selected {
              background-color: transparent;
              background: $btn_bg;
            }
          }
        }
      }
    }
  }
}

.live_event_full_modal {
  .modal-content {
    background-color: transparent !important;
    border-radius: 0;
    height: 100%;
    border: none;
    display: block;

    .full_post_carousel_section {
      width: 80%;
      height: 80%;

      .img_box {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }

    // .full_post_carousel_section {
    //     width: 80%;
    //     height: 80%;
    //     @media(max-width:767px) {
    //         width: 100%;
    //         height: 100%;
    //     }
    //     .carousel {
    //         width: 100%;
    //         height: 100%;
    //     }
    //     .carousel-control-prev {
    //         opacity: 1;
    //         height: 90%;
    //         .icon-prev {
    //             background-image: url("assets/img/carousel_left.svg");
    //             background-position: center;
    //             background-repeat: no-repeat;
    //             width: 45px;
    //             height: 45px;
    //         }
    //     }
    //     .carousel-control-next {
    //         opacity: 1;
    //         height: 90%;
    //         .icon-next {
    //             background-image: url("assets/img/carousel_right.svg");
    //             background-position: center;
    //             background-repeat: no-repeat;
    //             width: 45px;
    //             height: 45px;
    //         }
    //     }
    //     .carousel-indicators {
    //         display: none;
    //     }
    //     .carousel-inner {
    //         width: 100%;
    //         height: 100%;
    //         slide {
    //             width: 100%;
    //             height: 100%;
    //             .item {
    //                 width: 100%;
    //                 height: 100%;
    //             }
    //         }
    //         .img_box {
    //             width: 100%;
    //             height: 90%;
    //             img {
    //                 width: 100%;
    //                 height: 100%;
    //                 object-fit: contain;
    //                 object-position: center;
    //             }
    //         }
    //     }
    // }
  }
}

/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */

@import "~ngx-bootstrap/datepicker/bs-datepicker";

.emoji_btn {
  emoji-mart {
    .emoji-mart {
      width: 300px !important;

      @media (max-width: 575px) {
        width: 280px !important;
      }

      .emoji-mart-bar {
        border: none;

        emoji-preview {
          display: none;
        }
      }
    }
  }
}

mention-list {
  ul {
    margin: 0 !important;
    padding: 0 !important;

    li {
      &.active {
        a {
          color: $white !important;
          background-color: transparent !important;
          background: $btn_bg !important;
        }
      }

      a {
        display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
        padding: 5px 8px !important;

        img {
          width: 25px;
          height: 25px;
          border-radius: 100%;
          object-position: center;
          object-fit: cover;
          margin-right: 5px;
        }

        .user_name {
          font-size: $detail_txt;
          font-family: $PoppinsMedium;
          color: $black;
        }
      }
    }
  }
}

.display-inline {
  display: inline !important;
}

/* ======================== 
start chate massage window
============================ */

.chate-massage-window {
  .sent-chat-message-container {
    background: transparent !important;
    border: none !important;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 92%;

    span {
      color: $white;
      font-size: 12px !important;
      font-family: "PoppinsMedium" !important;
      background: linear-gradient(210deg, #ffb200 0%, $welcome_txt_red 100%);
      border-radius: 18px 18px 0px 18px;
      padding: 5px 15px;
      display: initial;
      margin-bottom: 5px;
      width: -moz-fit-content;
      width: fit-content;
      word-break: break-all;
    }

    .message-sent-date {
      color: $form_control_placeholder;
      font-size: 8px !important;
      font-family: "PoppinsRegular" !important;
      text-align: right;
      background: none !important;
      margin: 0 !important;
      padding: 0;
    }
  }

  .received-chat-message-container {
    display: flex;
    flex-direction: column;
    width: 92%;
    background: transparent !important;
    border: none !important;
    align-items: flex-start;
    margin-left: 30px !important;

    span {
      color: $black;
      font-size: 12px !important;
      font-family: "PoppinsMedium" !important;
      background: $white;
      border-radius: 18px 18px 18px 0;
      padding: 5px 15px;
      margin-bottom: 5px;
      width: -moz-fit-content;
      width: fit-content;
      word-break: break-all;
    }

    .message-sent-date {
      color: $form_control_placeholder;
      font-size: 8px !important;
      font-family: "PoppinsRegular" !important;
      text-align: left;
      background: none !important;
      margin: 0 !important;
      padding: 0;
    }
  }

  .ng-chat-footer {
    position: relative;
    border-top: 1px solid $date_bg !important;
    height: calc(50px) !important;

    .chat-window-input {
      background-color: $form_control_bg !important;
      padding: 6px 45px 6px 20px !important;
      border-radius: 25px !important;
      color: $black;
      font-size: 12px !important;
      font-family: "PoppinsRegular" !important;
      width: calc(100% - 20px);
      border: none;
      height: 40px;
      margin: 5px 10px;
    }

    // &::after {
    //     content: "";
    //     background: url("assets/img/sent.svg") no-repeat center;
    //     position: absolute;
    //     right: 16px;
    //     top: 8px;
    //     cursor: pointer;
    //     background-color: transparent;
    //     width: 34px;
    //     height: 34px;
    //     padding: 0;
    //     object-position: center;
    //     object-fit: contain;
    // }
  }

  .ng-chat-window {
    box-shadow: 0px 2px 10px $black !important;
    border-radius: 6px !important;
    border: none !important;

    .ng-chat-messages {
      height: calc(90% - 60px) !important;
    }
  }

  .ng-chat-title {
    height: 44px !important;
    line-height: 44px !important;
    position: relative;

    .ng-chat-participant-status {
      margin-top: 18px !important;
    }

    .ng-chat-close {
      font-size: 16px;
    }

    // &::after {
    //     content: "";
    //     background: url("assets/img/full-screen.svg") no-repeat center;
    //     position: absolute;
    //     right: 30px;
    //     top: 14px;
    //     cursor: pointer;
    //     background-color: transparent;
    //     width: 13px;
    //     height: 13px;
    //     padding: 0;
    //     object-position: center;
    //     object-fit: contain;
    // }
  }
}

/* ========================
End chate massage window
========================== */

.field_from {
  position: relative;

  .form-control {
    background: $form_control_bg url("../src/assets/img/drop.svg") no-repeat right !important;
    background-size: 13px !important;
    background-position: right 12px bottom 12px !important;
    -webkit-appearance: none;
  }
}

/* ========================
Start multiselect dropdown
========================== */

ng-multiselect-dropdown {
  .multiselect-dropdown {
    .dropdown-btn {
      border: 1px solid $form_control_border !important;
      background-color: $form_control_bg;
      font-size: $detail_txt;
      font-family: $PoppinsRegular;
      color: $black;
      border-radius: 5px !important;
      height: auto !important;
      padding: 10px 15px !important;

      &::placeholder {
        color: $form_control_placeholder;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .selected-item {
        background: linear-gradient(210deg,
            #ffb200 0%,
            $welcome_txt_red 100%) !important;
        border: transparent !important;
        color: $white !important;
        max-width: 150px !important;
      }

      .dropdown-multiselect__caret {
        &:before {
          background: url("../src/assets/img/drop.svg") no-repeat center !important;
          background-size: contain !important;
          border: none !important;
          padding: 7px !important;
          background-position: 0 0px !important;
        }
      }
    }

    .dropdown-list {
      margin-top: 5px !important;
      box-shadow: 0 0px 5px #dadada !important;

      ul {
        width: 100% !important;
        display: block !important;
        justify-content: inherit !important;

        li {
          width: 100% !important;
          font-size: $detail_txt !important;
          font-family: $PoppinsRegular !important;
          color: $black !important;

          input[type="checkbox"]+div {
            padding-left: 2.5em !important;

            &::before {
              margin-top: -10px !important;
              border: 1px solid #c7c7c7 !important;
              width: 16px !important;
              height: 16px !important;
            }
          }

          input[type="checkbox"]:checked+div {
            &::before {
              background: $welcome_txt_red !important;
              border: none !important;
            }

            &::after {
              margin-top: -6px !important;
              left: 3px !important;
            }
          }

          input[type="checkbox"][disabled]+div {
            opacity: 0.65 !important;
          }
        }
      }
    }
  }

  .disabled {
    opacity: 0.65 !important;
  }
}

/* ========================
End multiselect dropdown
========================== */

.add-post-carousel {
  .carousel-control-prev {
    display: none !important;
  }

  .carousel-control-next {
    display: none !important;
  }
}

.video-dimension {
  width: 100% !important;
  height: 300px !important;
}

.vjs-big-play-button {
  display: none !important;
}

.myVideo-dimensions {
  width: calc(100vw - 400px) !important;
  height: calc(100vh - 300px) !important;

  @media (max-width: 768px) {
    width: auto !important;
    height: auto !important;
  }
}

.video-js .vjs-control-bar {
  z-index: 9999 !important;

}

.create_btn_cls {
  background: $fill_btn_bg;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 400;
  border: transparent;
}

.avatar-mainimg {
  .avatar-gym-main-story {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 100%;
    object-position: center;
    margin: 0 -4px 0 -4px;
    border: 2px solid $white;
  }
}

.view-story-related-new-cls {
  background: $form_control_bg !important;

  .modal-content {
    background-color: transparent !important;
    border: transparent;
  }
}

.spacing-related-cls {
  margin: 0 3px 0 8px;
  cursor: pointer;
}

.same-all-side-overlay-add {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 390px;
    height: 613px;
    opacity: 0.7;
    right: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    top: 50%;

    @media only screen and (max-width: 1224px) {
      height: 445px;
      width: 340px;
    }
  }
}

.same-all-side-overlay-add-view {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    width: 648px;
    height: 775px;
    opacity: 0.7;
    right: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    top: 50%;

    @media only screen and (max-width: 1224px) {
      height: 775px !important;
      width: 646px !important;
    }

    @media only screen and (max-width: 767px) {
      height: 650px !important;
      width: 391px !important;
    }
  }
}

.view-story-logo {
  top: 0;
  margin: 50px 0px 0 25px !important;
}

.same-size-emoji {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.different-emoji {
  margin: 0 13px 0 13px;
}

.emoji-modal-add {
  padding: 0px !important;

  .modal-content {
    background-color: rgba(0, 0, 0, 0.6) !important;
    max-width: 647px !important;
    height: 776px;
    border-radius: 0px;
    justify-content: center;
    margin: 28px 0 0 0;

    @media only screen and (max-width: 767px) {
      max-height: 100% !important;
      height: 100%;
      margin: 0 !important;
    }
  }
}

.gym-shot-cls {
  height: 25px !important;

  @media only screen and (max-width: 375px) {
    height: 21px !important;
  }
}

@media only screen and (max-width: 1224px) {
  .small-modal {
    padding: 0 0px 0 0px !important;
  }
}

.new-mainly-custom-create .modal-content {
  max-width: 700px !important;
  margin: 0px !important;

  @media only screen and (max-width: 375px) {
    .same-all-side-overlay-add {
      &:before {
        height: 445px;
        width: 325px;
      }
    }
  }
}

.visually-hidden {
  visibility: hidden !important;
}

.new-ng-slider-inner-add {
  .carousel-inner {
    width: 648px;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-control-prev {
    width: 0px;
    margin: 0 0 0 0px;

    .carousel-control-prev-icon {
      background-image: url("../src/assets/img/leftslidercustom.svg") !important;
      background-repeat: no-repeat !important;
      position: absolute;
      left: 0;
      margin: -29px 0px 0 -98px;
      width: 90px;
      height: 90px;

      @media only screen and (max-width: 1224px) {
        margin: -29px 0px 0 0px;
      }

      @media only screen and (max-width: 767px) {
        margin: -29px 0px 0 135px;
        width: 70px;
        height: 70px;
      }
    }
  }

  .carousel-control-next {
    width: 0px;
    margin: 0 0 0 0px;

    .carousel-control-next-icon {
      background-image: url("../src/assets/img/rightslidercustom.svg") !important;
      background-repeat: no-repeat !important;
      position: absolute;
      right: 0;
      margin: -29px -98px 0 0;
      width: 90px;
      height: 90px;

      @media only screen and (max-width: 1224px) {
        margin: -29px 0px 0 0px;
      }

      @media only screen and (max-width: 767px) {
        margin: -29px 135px 0 0;
        width: 70px;
        height: 70px;
      }
    }
  }

  .same-all-side-overlay-add-view {
    &:before {
      content: "";
      width: 648px !important;
      height: 773px !important;
      // z-index: 1;
      margin: 0px 0 0 0 !important;

      @media only screen and (max-width: 1224px) {
        height: 775px !important;
        width: 646px !important;
      }

      @media only screen and (max-width: 767px) {
        height: 649px !important;
        width: 415px !important;
        margin: 0px 0 0 0 !important;
      }
    }
  }
}

// messsagemodal scss
.reels-modal {
  .modal-content {
    width: 375px !important;
    height: 100vh !important;
    background: $white;
    padding: 13px;
    position: absolute;
    right: 0;
    z-index: 11;
    border-radius: 0px !important;
    margin: 0 2px 0 0px;

    @media only screen and (max-width: 767px) {
      width: auto !important;
      padding: 8px;
      overflow: scroll;
    }
  }

  .change-profilecamera {
    position: absolute;
    margin: 63px 0px 0 80px;
    cursor: pointer;
  }

  .main-story-add-right {
    .avatar-gym-main-story {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 100%;
      object-position: center;
      margin: 0 -4px 0 0px;
      border: 2px solid $white;
    }

    .text-portion {
      h6 {
        font-size: 17px;
        font-weight: 800;
        color: $black;
      }

      p {
        color: $welcome_txt_color;
        font-size: 13px;
        max-width: 213px;
        word-break: break-word;
      }
    }

    .out-side-add {
      .outside-msg {
        color: $welcome_txt_color;
        font-size: 13px;
        max-width: 413px;
        word-break: break-word;
        margin: 8px 0 0 0;
      }
    }

    .mintue-portion {
      span {
        color: $form_control_placeholder;
        font-size: 12px;
      }
    }

    .close-story {
      margin: 0 13px 0 0;
      background-color: $form_control_bg;
      // box-shadow: 0px 0px 13px $black;
      padding: 5px 10px 5px 10px !important;
      border-radius: 50%;
      font-size: 31px;
      font-weight: 400;
    }

    .bottom-message-part-area {
      padding: 10px 0px;
      border-top: 1px solid $date_bg;
      position: absolute;
      width: 100%;
      bottom: 0;
      margin: 0 0px 0 0px;
      left: 0;

      @media only screen and (max-width: 767px) {
        position: relative;
      }

    }

    .input_msg_write {
      position: relative;
      padding: 0 13px;
    }

    .write_msg {
      background-color: $form_control_bg;
      padding: 10px 75px 10px 20px;
      border-radius: 25px;
      color: $black;
      font-size: 12px;
      font-family: "PoppinsRegular";
      width: 100%;
      border: none;
    }

    .msg_send_btn {
      position: absolute;
      right: 0;
      top: -1px;
      cursor: pointer;
      background-color: transparent;
      width: 40px;
      height: 40px;
      padding: 0;
      margin: 0 13px 0 0px;

      img {
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: contain;
      }
    }

    .title-story {
      h6 {
        color: $black;
        font-size: 18px;
        font-weight: 800;
      }
    }

    .views-story-scroll {
      max-height: 818px;
      overflow-y: auto;
      display: block;
      position: relative;

      @media only screen and (max-width: 767px) {
        max-height: 518px;
        padding-right: 11px;
      }
    }

    .like-btn {
      .follow-btn {
        background: $fill_btn_bg;
        border-radius: 4px;
        border: transparent;
        font-size: 14px;
        cursor: pointer;
      }

      .unfollow-btn {
        color: $following_btn_bg;
        font-size: 16px;
        font-weight: 800;
        background-color: transparent;
        border: transparent;
        cursor: pointer;
      }
    }
  }
}

.home-page-different-view-open {
  .carousel-inner {
    background: rgba(0, 0, 0, 0.35);
    padding: 73px 45px 33px 45px;
  }

  .main-story {
    #overlay_home_view {
      .music-portion-main {
        width: 538px !important;
        margin-top: 13px !important;

        @media only screen and (max-width: 767px) {
          width: 338px !important;
        }
      }
    }
  }

  .top-portion {
    margin: -64px 0px 0 19px !important;
    width: 518px !important;

    @media only screen and (max-width: 767px) {
      margin: -59px 0px 0 109px !important;
      width: 335px !important;
    }
  }

  .unique-portion-add {
    position: absolute !important;
    z-index: 11;
    margin: 30px 0 0 20px;

    @media only screen and (max-width: 767px) {
      margin: 30px 0 0 110px;
    }
  }

  .landscape-height-add {
    height: 650px !important;
    width: 648px !important;

    @media only screen and (max-width: 767px) {
      height: 520px !important;
      margin: 0 auto;
      width: 355px !important;
      display: flex;
      justify-content: center;
    }

    @media only screen and (max-width: 375px) {
      height: 365px !important;
    }
  }

  .massege-part {
    @media only screen and (max-width: 767px) {
      margin: 0px 0px 25px 110px !important;
    }
  }

  .textarea-edit {
    width: 418px !important;

    @media only screen and (max-width: 767px) {
      width: 245px !important;
    }
  }

  .modal-content {
    .main-custom-inner {
      .main-story {
        #overlay {
          .new-cls-add-viewstory {
            margin-top: 13px !important;
          }
        }
      }
    }
  }

  .same-all-side-overlay-add-view {
    &:before {
      width: 558px !important;
      height: 652px !important;

      @media only screen and (min-width: 768px) and (max-width: 1224px) {
        height: 650px !important;
      }

      @media only screen and (max-width: 767px) {
        width: 356px !important;
        height: 522px !important;
        margin: 0px !important;
      }

      @media only screen and (max-width: 375px) {
        height: 365px !important;
      }
    }
  }
}

.instaonly-view-story-cls {
  .carousel-inner {
    background: rgba(0, 0, 0, 0.35);
    padding: 73px 45px 33px 45px;
  }

  .music-portion-main {
    width: 538px !important;

    @media only screen and (max-width: 767px) {
      width: 338px !important;
    }
  }

  .top-portion {
    margin: -64px 0px 0 19px !important;
    width: 518px !important;

    @media only screen and (max-width: 767px) {
      margin: -59px 0px 0 109px !important;
      width: 335px !important;
    }
  }

  .unique-portion-add {
    position: absolute !important;
    z-index: 11;
    margin: 30px 0 0 20px;

    @media only screen and (max-width: 767px) {
      margin: 30px 0 0 110px;
    }
  }

  .landscape-height-add {
    height: 700px;
  }

  .same-all-side-overlay-add-view {
    &:before {
      width: 558px !important;
      height: 652px !important;

      @media only screen and (min-width: 768px) and (max-width: 1224px) {
        height: 700px !important;
      }

      @media only screen and (max-width: 767px) {
        width: 356px !important;
        height: 522px !important;
        margin: 0px !important;
      }

      @media only screen and (max-width: 375px) {
        height: 365px !important;
      }
    }
  }

  .textarea-edit {
    width: 418px !important;
  }

  .modal-content {
    .main-custom-inner {
      .main-story {
        #overlay {
          .new-cls-add-viewstory {
            margin-top: 13px !important;
          }
        }
      }
    }
  }
}

.unavailable-story {
  font-size: 16px;
  background-color: #f9f9f9;
  width: max-content;
  padding: 9px;
  border-radius: 50px;
}

//tips modal related cls
.tipsadd-modal {
  .modal-content {
    max-width: 500px !important;
  }

  .main-story-add-right {
    padding: 23px 15px 15px 15px;
  }

  .title-story {
    h6 {
      color: $black;
      font-size: 19px;
      font-weight: 700;
    }
  }

  .tips-story-add-tips {
    padding: 0px 18px 18px 18px;

    .text-portion {
      h6 {
        color: $black;
        font-weight: 800;
      }

      p {
        color: $welcome_txt_color;
        font-size: 13px;
      }
    }
  }

  .read-more-check {
    opacity: 0;
    position: absolute;
    pointer-events: none;
  }

  .readmore-click-add {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-line;
    word-wrap: break-word;
    transition: max-height 0.5s ease;
    line-height: 1.5;
    text-overflow: ellipsis;
    max-height: 40px;
  }

  input:checked+.readmore-click-add {
    -webkit-line-clamp: unset;
    max-height: 330px;
  }

  input:checked~.button-read-click-close {
    opacity: 0;
    visibility: hidden;
    max-height: 0;
  }

  .button-read-click-close {
    display: block;
    color: #ff1b00;
    text-transform: uppercase;
    font-size: 0.8em;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    max-height: 18px;
    width: fit-content;
    font-weight: 800;
    transition: max-height 0.5s ease, opacity 0.5s ease, visibility 0.5s 0.5s;
  }

  .input-btn-cls {
    .tipsinput {
      width: 80px;
      margin: 0 8px 0 8px;
      border: 1px solid $checkbox_border;
      border-radius: 4px;
      font-size: 17px;
      color: $black;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::placeholder {
        font-size: 17px;
        color: $black;
      }

      &:focus-visible {
        outline: none;
        border: none;
      }
    }
  }

  .other-btn {
    border-radius: 4px;
    font-size: 17px;
    border: transparent;
    color: $white;
    background: $tips_btn;
    padding: 9px 30px 9px 30px;

    @media only screen and (max-width: 767px) {
      padding: 9px 18px 9px 18px;
    }

    &:hover {
      color: $white;
    }
  }

  .enter-amount-btn {
    background: $form_control_bg 0% 0% no-repeat padding-box;
    border: 0.5px solid $form_control_border;
    border-radius: 3px;
    font-size: 15px;
    color: $form_control_placeholder;
    height: 45px;

    &:hover {
      background: $form_control_bg 0% 0% no-repeat padding-box;
      border: 0.5px solid $form_control_border;
      border-radius: 3px;
      font-size: 15px;
      color: $form_control_placeholder;
    }

    &:focus-visible {
      outline: none;
      border: none;
    }

    &:focus {
      background: $form_control_bg 0% 0% no-repeat padding-box;
      border: 0.5px solid $form_control_border;
      border-radius: 3px;
      font-size: 15px;
      color: $form_control_placeholder;
    }
  }

  .wallet-text {
    p {
      color: $welcome_txt_color;
      font-size: 18px;
      font-weight: 700;
    }

    .different-amount {
      font-size: 18px;
      color: $black;
      font-weight: 700;
    }
  }

  .text-area-tips {
    h6 {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px !important;
    }

    .textarea-cls {
      border: 0.5px solid $checkbox_border;
      border-radius: 6px;
      resize: none;
      text-align: left;
      padding: 8px 0 0 8px;
      color: #5c5c5c !important;

      &::placeholder {
        color: #5c5c5c !important;
        font-weight: 500;
        text-align: left;
      }
    }
  }

  .send-button {
    .sending-tips {
      background: $post_btn_bg;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 600;
      border: transparent;
      padding: 11px 30px 11px 30px;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }

  .avatar-gym-main-story {
    width: 63px;
    height: 63px;
  }

  .spacing-tips {
    margin: -5px 0 0 0;
    opacity: 1 !important;

    .close-tips {
      font-size: 30px;
    }
  }

  .custom-add-w-h {
    .avatar-gym-main-story {
      width: 52px !important;
      height: 52px !important;
    }
  }

  .text-portion {
    h6 {
      font-size: 17px;
      font-weight: 800;
      color: $black;
    }

    p {
      color: $welcome_txt_color;
      font-size: 13px;
      max-width: 213px;
      word-break: break-word;
    }
  }

  .text-portion {
    h6 {
      font-size: 17px;
      font-weight: 800;
      color: $black;
    }

    p {
      color: $welcome_txt_color;
      font-size: 13px;
      max-width: 100%;
      word-break: break-word;
    }
  }

  .like-btn {
    .follow-btn {
      background: $fill_btn_bg;
      border-radius: 4px;
      border: transparent;
      font-size: 14px;
      cursor: pointer;
    }

    .unfollow-btn {
      color: $following_btn_bg;
      font-size: 16px;
      font-weight: 800;
      background-color: transparent;
      border: transparent;
      cursor: pointer;
    }
  }

  .views-story-scroll {
    padding: 0 18px 0 18px;
    max-height: 818px;
    overflow-y: auto;
    display: block;
    position: relative;

    @media only screen and (max-width: 767px) {
      max-height: 518px;
      padding: 0 14px 0 3px;
    }
  }
}

.story_upload_view {
  .main-story {
    .music-portion-main {
      width: 558px !important;

      @media only screen and (max-width: 767px) {
        width: 348px !important;
      }

      .progress {
        margin-bottom: 79px;
      }
    }
  }
}

.outgoing_msg {
  #different_cls_view {
    margin: 50px auto 50px auto !important;
    width: 618px !important;
  }

  .carousel-inner {
    background: transparent;
    padding: 0;
  }

  #overlay {
    .music-portion-main.new-cls-add-viewstory {
      width: 618px !important;

      @media only screen and (max-width: 767px) {
        width: 330px !important;
      }

      .music-progress {
        margin: 90px 0 0 0 !important;
      }
    }
  }

  .same-all-side-overlay-add-view {
    &:before {
      width: 648px !important;
      height: 650px !important;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        height: 700px !important;
      }

      @media only screen and (max-width: 767px) {
        height: 520px !important;
        width: 354px !important;
      }

      @media only screen and (max-width: 375px) {
        width: 356px !important;
        height: 366px !important;
        margin: 0px !important;
      }
    }
  }

  .top-portion {
    width: 613px !important;
    margin: 63px 0px 0 19px !important;

    @media only screen and (max-width: 767px) {
      width: 335px !important;
      margin: 60px 0px 0 160px !important;
    }

    @media only screen and (max-width: 375px) {
      width: 345px !important;
      margin: 60px 0px 0 155px !important;
    }
  }

  .new_story_modal_add {
    .flex {
      .massege-part {
        @media only screen and (max-width: 767px) {
          margin: 0px 0px 18px 152px !important;
        }

        .message-box {
          .textarea-edit {
            width: 520px !important;

            @media only screen and (max-width: 767px) {
              width: 240px !important;
            }
          }
        }
      }
    }
  }
}

.outgoing_side_Edit_part {
  position: absolute;
  margin: 6px 0px 0px 0px;
  background-color: rgb(0 0 0 / 40%);
  border-radius: 4px 4px 0px 0px;
  padding: 4px 16px 4px 17px;
  width: 175px;

  h6 {
    color: white;
    word-break: break-word;
  }

  .avatar-gym-main-story {
    width: 35px;
    height: 36px;
  }
}

.spotify-signin-signup {
  background: #f5f5f5;

  .modal-content {
    max-width: 813px !important;
    border-radius: 0px;
    border: 1px solid #dedede;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 8%), 0 6px 20px 0 rgb(0 0 0 / 3%);

    @media only screen and (max-width: 767px) {
      height: fit-content !important;
    }
  }

  .spotify-close {
    position: relative;

    .close {
      position: absolute;
      right: 0;
      margin: 16px 16px 0 0;
      background: #f5f5f5;
      padding: 13px 18px 13px 18px;
      border-radius: 27px;
    }
  }

  .spotify-logo {
    max-width: 180px;
    height: 50px;
  }

  .spotify-inner-portion {
    max-width: 513px;
    margin: 0 auto;
    text-align: center;
    display: block;

    h6 {
      font-size: 19px;
      color: #000;
      font-weight: 800;
      margin-top: 25px;
    }
  }

  .spotify-form {
    label {
      font-size: 17px;
      font-weight: 600;
    }

    .spotify-input {
      background-color: transparent !important;
      border: 1px solid #858585 !important;
      color: #858585 !important;
      height: 50px !important;
      font-size: 18px !important;
      font-weight: 700 !important;

      &::placeholder {
        color: #858585 !important;
      }
    }

    a {
      font-weight: 700;
      text-decoration: none;
      font-size: 18px;
      color: #424242 !important;
    }

    .spotify-input-check {
      width: 25px;
      height: 25px;
    }
  }

  .social-btn {
    .facebook_btn {
      background: #425b91;
      padding: 10px 30px 10px 30px;
      color: $white;
      font-size: 17px;
      border-radius: 30px;
      border: transparent;
      width: 475px;
      font-weight: 600;

      @media only screen and (max-width: 767px) {
        width: 335px;
      }
    }

    .apple_btn {
      background: #1a1a1a;
      padding: 10px 30px 10px 30px;
      color: $white;
      font-size: 17px;
      border-radius: 30px;
      border: transparent;
      width: 475px;
      font-weight: 600;

      @media only screen and (max-width: 767px) {
        width: 335px;
      }
    }

    .google_btn {
      padding: 10px 30px 10px 30px;
      color: #858585;
      font-size: 17px;
      background: transparent;
      border-radius: 30px;
      border: 1px solid #858585;
      width: 475px;
      font-weight: 600;

      @media only screen and (max-width: 767px) {
        width: 335px;
      }
    }
  }

  .login-btn {
    button {
      background-color: #6ad376 !important;
      border: transparent;
      border-radius: 30px;
      font-size: 17px;
      font-weight: 700;
      padding: 14px 28px 14px 28px;
      color: #1a1a1a;
    }
  }

  input[type="checkbox"]:checked {
    accent-color: #479c51;
  }

  input[type="checkbox"]:after {
    box-shadow: none !important;
  }
}

.delete_story_modal {
  background: rgba(0, 0, 0, 0.5);

  .modal-content {
    max-width: 500px;
  }

  h4 {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }

  p {
    color: #666666;
    font-size: 16px;
  }

  .add-close-open {
    position: absolute;
    right: 0;
    top: 0;
    margin: 50px 52px 0px 0px !important;
    padding: 13px 18px 13px 18px !important;
    opacity: 1;
  }
}

.share-story {
  .share-heading {
    h6 {
      color: $black;
      font-size: 18px;
      font-weight: 600 !important;
      margin-bottom: 0px !important;
    }
  }

  .close-story {
    margin: 0px 0px 0 0;
    top: 0;
    background: #f5f5f5;
    padding: 8px 13px 8px 13px !important;
    border-radius: 50%;
    font-size: 34px;
    font-weight: 400;
    color: #666666;
    opacity: 1 !important;
  }

  .search-option {
    border-radius: 4px;
    padding: 0px 0px 0px 0px;

    .input-group {
      background: #f5f5f5;
      height: 50px;
    }

    .input-group-text {
      background: transparent;
      border: transparent;
    }

    .search-something {
      border: transparent;
      background: #f5f5f5;
      width: 79%;
      border-radius: 4px;

      @media only screen and (max-width: 375px) {
        width: 75%;
      }

      &:focus {
        box-shadow: transparent !important;
        border-color: transparent !important;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }

  .create-fan-channel {
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 29px;
  }

  .closing-fan-channel {
    position: absolute;
    margin: 25px 0px 0px -29px;

    .fanchat-close {
      background: transparent linear-gradient(207deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
      opacity: 1 !important;
      border-radius: 30px;
      padding: 1px 5px 1px 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: $white !important;
        font-size: 17px;
        font-weight: 400;
      }
    }
  }

  .fanavatar-profile {
    h6 {
      color: $black;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .share-story-scroll {
    max-height: 618px;
    overflow-y: auto;
    display: block;
    padding: 0px 20px 0px 4px;
    margin-bottom: 13px;

    @media only screen and (max-width: 1275px) {
      max-height: 358px !important;
      padding: 0px 20px 0px 20px;
    }

    @media only screen and (max-height: 540px) {
      max-height: 288px !important;
      padding: 0px 20px 0px 20px;
    }

    @media only screen and (max-width: 767px) {
      max-height: 438px !important;
      padding: 0px 20px 0px 20px;
    }

    @media only screen and (max-width: 375px) {
      max-height: 268px !important;
      padding: 0px 20px 0px 20px;
    }

    .checksearch-input {
      .search-input-check {
        display: block;
        position: relative;
        padding-left: 32px;
        margin-bottom: 0px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
      }
    }

    .avatar-gym-main-story {
      width: 48px;
      height: 48px;
      object-fit: cover;
      border-radius: 100%;
      object-position: center;
      margin: 0 -4px 0 -4px;
      border: 2px solid $white;
    }

    .checkmark {
      position: absolute;
      top: -18px;
      left: 0;
      height: 30px;
      width: 30px;
      background-color: transparent;
      border: 2px solid #707070;
      border-radius: 50%;

      &:after {
        left: 9px;
        top: 5px;
        width: 8px;
        height: 13px;
        border: solid $white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .input-btn-cls {
    .tipsinput {
      max-width: 100%;
      width: 100%;
      text-align: left;
      margin: 0 0px 0 0px;
      border: 0.5px solid $checkbox_border;
      border-radius: 6px;
      font-size: 17px;
      color: #999999;
      height: 50px;
      background: #f5f5f5;
      padding-left: 8px;
      font-weight: 700;

      &::placeholder {
        font-size: 17px;
        color: #999999;
        font-weight: 700;
      }

      &:focus-visible {
        outline: none;
        border: 0.5px solid $checkbox_border;
      }
    }
  }

  .upload-img-channel {
    background: #e5e5e5;
    width: 113px;
    height: 113px;
    margin: 0 auto;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .participatns-ics {
    background: transparent linear-gradient(218deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
    border-radius: 50px;
    width: 42px;
    height: 42px;
    padding: 10px 8px 10px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fanchannel-story {
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border: 0.5px solid #e2e2e2;
    width: 100px;
    height: 100px;
  }

  .fanchannel-story-scroll {
    max-height: 450px;
  }

  .media-images {
    overflow-x: scroll;

    .edit-width {
      width: max-content;
    }

    .video-ics {
      position: absolute;
      margin: 14px 0px 0 -50px;
    }

    img {
      width: 60px;
      height: 60px;
      margin: 0 8px 0 0;
      object-fit: cover;
    }
  }

  .option-ics {
    .edit-drop-fan-menu {
      border-radius: 10px;
      box-shadow: 0px 2px 10px #000;
      background-color: #fff;
      border: none;
      right: 0 !important;
      left: auto !important;
      padding: 8px;
      margin: 0;
      min-width: 130px;

      a {
        color: #000;
        font-size: 12px;
        font-family: "PoppinsMedium";
        padding: 8px 10px;
        margin-bottom: 5px;
      }

      .delete-remvoe-ics {
        color: #ff2200;
      }
    }
  }

  .custom-set {
    bottom: inherit !important;
  }

  .bottom-message-part-area {
    padding: 10px 0px;
    border-top: 1px solid $date_bg;
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: 0 0px 0 0px;
    left: 0;

    .type_msg {
      padding: 10px 15px 0px 15px;
      border-top: 1px solid $chat_border_color;

      .crate-btn-cls {
        margin: 0 auto;
        text-align: center;
      }

      .outline_border {
        background: transparent !important;
        border: 1px solid $welcome_txt_color !important;
        color: $welcome_txt_color !important;
      }

      .send-button {
        .sending-tips {
          background: $post_btn_bg;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 600;
          border: transparent;
          padding: 11px 30px 11px 30px;
          color: $white;

          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .text-area-tips {
    h6 {
      color: $black;
      font-size: 15px;
      font-weight: 600;
    }

    .textarea-cls {
      border: 0.5px solid $checkbox_border;
      border-radius: 6px;
      resize: none;
      text-align: left;
      padding: 8px 0 0 8px;
      background: #f5f5f5;
      color: #999999 !important;
      font-weight: 700;

      &::placeholder {
        color: #999999 !important;
        font-weight: 700;
        text-align: left;
      }
    }

    p {
      color: $welcome_txt_color;
      font-size: 15px;
    }
  }

  .both-side-speace {
    padding: 0px 0px 0px 0px;
  }

  .share-as-challenge-mode {
    max-height: 463px;
  }

  .share-as-challenge-mode-fan-channel {
    max-height: 460px;
  }
}

.send-btn-share-cls {
  padding: 10px 23px;
  border-top: 1px solid #f1f1f1;
  position: absolute;
  width: 100%;
  bottom: 0;
  margin: 0 0px 0 0px;
  left: 0;
  background: #fff;

  .send-btn-reels-share-all {
    background: transparent linear-gradient(208deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px;
    font-size: 17px;
    border: transparent;
    padding: 10px 0 10px 0;
  }
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* When the checkbox is checked, add a blue background */
.search-input-check input:checked~.checkmark {
  background: transparent linear-gradient(209deg, #ffa400 0%, #ff2200 100%) 0% 0% no-repeat padding-box;
  border: 2px solid rgb(255 34 0 / 10%) !important;
}

/* Show the checkmark when checked */
.search-input-check input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.search-input-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.media-images::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  height: 10px;
}

.media-images::-webkit-scrollbar-thumb {
  background: transparent linear-gradient(260deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.media-images::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.fanchat-modal-add .modal-content {
  height: auto !important;
  top: 0 !important;
}

.delete-open-modal {
  .modal-content {
    position: inherit !important;
    height: fit-content !important;
    border-radius: 15px !important;
  }

  h2 {
    color: $black;
    font-size: 26px;
    font-weight: bold;
  }

  p {
    color: $welcome_txt_color;
    font-size: 15px;
  }

  .send-button {
    .sending-tips {
      background: $post_btn_bg;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 600;
      border: transparent;
      padding: 11px 30px 11px 30px;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}

.dropdown-item:active {
  background: transparent !important;
}

.attachment {
  .edit-drop-fan-menu {
    border-radius: 10px;
    box-shadow: 0px 2px 10px $black;
    background-color: $white;
    border: none;
    right: 0 !important;
    left: auto !important;
    padding: 8px;
    margin: 0;
    min-width: 130px;
    top: 0 !important;
    margin-top: -195px;

    a {
      color: $black;
      font-size: 12px;
      font-family: "PoppinsMedium";
      padding: 8px 10px;
      margin-bottom: 5px;
    }
  }
}

.edit-gymshot-modal {
  .gymshotlogoadd {
    width: 175px;
    height: 53px;
  }

  .outline_border {
    background: transparent !important;
    border: 1px solid $welcome_txt_color !important;
    color: $welcome_txt_color !important;
    padding: 11px 30px 11px 30px;
    font-size: 16px;
    font-weight: 600;
  }
}

.sharechallengeseperate {
  .chat_img {
    .fanchannel-bg {
      background: #f3f3f3 0% 0%;
      border: 0.5px solid #e2e2e2;
      border-radius: 59px;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2px 0 2px;

      .custom-gradient-bg {
        label {
          font-size: 10px;
          position: absolute;
          background: transparent linear-gradient(204deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
          border-radius: 28px;
          width: 16px;
          height: 16px;
          margin: -8px 0px 0 -26px;
          display: flex;
          justify-content: center;
          color: $white;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-height: 713px) {
  .new-ng-slider-inner-add {
    .same-all-side-overlay-add-view {
      &:before {
        height: 450px !important;
      }
    }
  }

  .chatstory-view-related-cls {
    .main-custom-inner {
      .main-story {
        .new_story_modal_add {
          .landscape-height-add {
            height: 513px !important;
          }
        }
      }
    }
  }

  .same-all-side-overlay-add {
    &:before {
      height: 513px !important;
    }
  }

  .gym-imgs {
    height: 513px;
  }

  .canvas-cusom-video-height {
    canvas {
      height: 363px !important;
      width: 312px !important;
    }
  }

  .blank-screen-cls {
    height: 375px;
  }

  .effect-carousel {
    max-width: 308px !important;
  }

  .main-story-add-right {
    h6 {
      font-size: 15px;
    }

    p {
      font-size: 14px;
    }
  }
}

.modal-dialog.small-modal {
  .modal-content {
    border: 0px;
  }
}

mention-list {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
}

// support ticket related css start
.support-portion-new {
  .support-ticket-section {
    .title-portion-support-ticket {
      h6 {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
      }
    }

    .crate-btn-cls {
      .outline_border {
        color: #ff1b00 !important;
        border: 1px solid #ff1b00;
        font-weight: 600;
        border-radius: 4px;
        font-size: 14px;
        padding: 6px 25px 6px 25px;
      }
    }
  }

  .support-ticket-inner-portion {
    background: #ffffff;
    box-shadow: 0px 2px 10px #0000001a;
    border-radius: 8px;
    padding: 18px;

    .category-account {
      label {
        font-size: 14px;
        color: #000000;
        font-weight: 400;
      }

      .diff-color {
        color: #666666;
        font-weight: 400;
      }
    }

    .raisedon-date {
      label {
        color: #999999;
        font-size: 14px;
      }
    }

    .photo-verification {
      span {
        color: #666666;
        font-size: 14px;
        font-weight: 400;
      }

      p {
        font-size: 16px;
        color: #000000;
        font-weight: 600;
      }
    }

    .active-close-btn {
      .active_btn {
        background: #2ace761a 0% 0% no-repeat padding-box;
        border: 0.5px solid #2ace76;
        border-radius: 2px;
        font-size: 14px;
        color: #2ace76 !important;
        padding: 6px 25px 6px 25px;
      }

      .close_active_btn {
        background: #ff22001a 0% 0% no-repeat padding-box;
        border: 0.5px solid #ff2200;
        border-radius: 2px;
        font-size: 14px;
        color: #ff2200 !important;
        padding: 6px 25px 6px 25px;
      }
    }
  }

  .remove-support-ticket-cls {
    background: transparent;
    box-shadow: none;
    padding-left: 0px;
  }

  .ticket-inner-cls {
    h6 {
      font-size: 13px;
      color: #000000;
      font-weight: 600;
    }

    p {
      font-size: 14px;
      line-height: normal;
      color: #000000;
    }

    span {
      color: #666666;
      font-size: 14px;
    }
  }
}

// support ticket related css over

.without-any-tips {
  color: #ff2200;
  font-size: 31px;
  font-weight: 800;
  text-align: center;
}

.without-any-tips-cls-add {
  display: flex;
  align-items: center;
  justify-content: center;
}

//create ticket design css start
.create-ticket-design-template {
  .upload-message-img {
    button {
      background: #666666;
      font-size: 16px;
      color: #fff;
      font-weight: 400;
      border: transparent;
      border-radius: 4px;
      height: 48px;
    }
  }

  .upload-image-click {
    img {
      border-radius: 8px;
      float: left;
      width: 50%;
      height: 150px;
      object-fit: cover;
      margin: 5px 0px 5px 0;
      padding: 0 3px 0 3px;
    }

    .delete-all-ics {
      cursor: pointer;
      margin: 13px 0px 0px -43px;
      float: left;
    }
  }
}

.profile-verification-box-main {
  .profile-verification-box {
    border: 1px solid #c7c7c7;
    border-radius: 8px;
    padding: 8px;
    margin: 13px 0;
  }

  .verification-text {
    p {
      color: #666666;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .passpoert-driving-lics {
    font-size: 10px;
    color: #999999;
  }

  .submit-documents {
    margin: 13px 0;

    .submit-text {
      h6 {
        color: #000000;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .description-text {
      p {
        color: #666666;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .profile-verification-text {
    margin: 13px 0;

    h6 {
      color: #000000;
      font-size: 14px;
      font-weight: 600;
    }

    p {
      color: #ff9100;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.support-ticket-cls-create {
  img {
    text-align: center;
    width: 100%;
    height: 200px;
    object-position: center;
    object-fit: contain;
  }

  .support-ticket-portion {
    color: #666666;
    max-width: 360px;
    margin: 13px auto;
    text-align: center;
    font-size: 14px;
    display: block;
  }

  .support-ticket-btn-cls {
    text-align: center;
    margin: 8px auto;

    button {
      border: 1px solid #ff2200;
      border-radius: 21px;
      color: #ff2200;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.read-more-check {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.readmore-click-add {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
  word-wrap: break-word;
  transition: max-height 0.5s ease;
  line-height: 1.5;
  text-overflow: ellipsis;
  max-height: 40px;
  transition: bottom 0.5s linear 0s;
}

input:checked+.readmore-click-add {
  -webkit-line-clamp: unset;
  max-height: 330px;
  transition: max-height 0.5s linear 0s;
}

input:checked~.button-read-click-close {
  opacity: 1;
  visibility: unset;
  max-height: inherit;
  transition: max-height 0.5s linear 0s;
}

.button-read-click-close {
  display: block;
  color: #ff1b00;
  text-transform: uppercase;
  font-size: 0.8em;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  max-height: 18px;
  width: fit-content;
  font-weight: 800;
  transition: bottom 0.5s linear 0s;
}

.custom-cls-add {
  label {
    color: #ffb200;
  }
}

// Canvas related css start
.canvas-cusom-video-height canvas {
  height: 512px;
  width: 356px;
  object-fit: contain;
  border-radius: 13px;

  @media only screen and (max-width: 767px) {
    height: 350px !important;
    width: 243px !important;
  }
}

//Canvas related css over

.shots-cls {
  .modal-content {
    width: 613px !important;

    @media only screen and (max-width: 767px) {
      max-width: 430px !important;
      height: 563px;
    }
  }

  .tipsadd-modal {
    padding: 18px 18px 18px 18px;
  }
}

.shots-cls-video {
  .modal-content {
    width: 613px !important;
    height: fit-content !important;
    padding: 18px;
  }
}

.blank-screen-cls {
  width: 318px;
  height: 418px;
  border-radius: 13px;
  background-color: rgba(0, 0, 0, 0.4);
  margin: 0 auto;
  position: relative;

  .timing-video-ics {
    position: absolute;
    bottom: 0;
    margin: 0 0px 0 0;
    left: 0;
    right: 0;
    z-index: 11;
  }

  .custom-video-start-pause-ics {
    .video-pause-start {
      background: #e6e6e6;
      border-radius: 50%;
      padding: 8px;
    }

    .timing-heading {
      .timing-text {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        margin-top: 8px;
      }
    }
  }

  .shoot-video-all video {
    height: 390px;
    width: 318px;
    object-fit: contain;
  }
}

.new-profile-upload-section {
  input[type="file"] {
    left: 0;
    margin: -36px 0px 0px -90px;
    position: absolute;
    right: 0;
    height: 48px;
    cursor: pointer;
    width: -moz-fit-content;
    width: 450px;
    opacity: 0;
  }
}

.scrolling-auto-height {
  max-height: 563px;
  overflow-y: auto;
}

.enter-amount .input-group .ng-invalid.ng-touched {
  border: 1px solid #d70000 !important;
  color: #d70000 !important;
}

.computer-filter-ics {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.cross-cls-all {
  position: absolute;
  right: 0;
  z-index: 1;
  margin: -40px 20px 0 0;
  cursor: pointer;
}

.wavesurfer-region {
  border: 3px solid #181818;
}

.custom_red_title {
  .red_tab {
    color: #ff1b00;
    font-size: 14px;
    font-family: "RubikMedium";
    cursor: pointer;
  }
}

.custom_green_title {
  .green_tab {
    color: #2ace76;
    font-size: 14px;
    font-family: "RubikMedium";
    cursor: pointer;
  }
}

.custom-classes-add-tips .text-portion h6 {
  font-size: 14px;
}

.custom-classes-add-tips .views-story-scroll {
  padding: 2px;
}

.custom-classes-add-tips .main-story-add-right {
  padding: 23px 12px 15px 12px;
}

.set-video-ics {
  position: absolute;
  margin: 92px 63px 0 0px;
}

.set-video-ics-received {
  position: absolute;
  margin: 92px 0px 0 70px;
}

// Pills Record btn new design start record
.pills-new-button-record-design li button.active {
  background: transparent linear-gradient(207deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
  font-size: 15px;
  font-weight: 600;
}

.pills-new-button-record-design li button {
  font-size: 15px;
  font-weight: 600;
}

.remove-scroll-in-recording {
  max-height: 620px !important;
}

.bg-white-add {
  background-color: #fff !important;
}

// Pills Record btn new design over record

.custom-chat-profile-cls {
  margin-top: -113px !important;
}

.new-fan-channel-support-only-this-cls .modal-content {
  height: 100vh !important;
}

.new-fan-channel-support-only-this-cls .bottom-message-part-area {
  bottom: 0 !important;
}

.fan-channel-cls .chat_list .chat_img:before {
  content: none !important;
}

.delete-btn-tips {
  padding: 13px 63px 13px 63px !important;
}

.fees-common-full-width-step-indicator .music-portion-main.progress-bar-set-proper .progress-bar-portion {
  width: 100% !important;
}

@media only screen and (max-width: 1280px) {
  .first-one-seperate-cls .share-story-scroll.share-as-challenge-mode {
    max-height: 244px !important;
  }

  .share-story.main-story-add-right.final-change-responsive .height-custom-only-this-cls {
    max-height: 213px !important;
  }

  .new-fan-channel-support-only-this-cls .modal-content {
    height: fit-content !important;
  }

  .notification-cls-overflow {
    height: calc(100vh - 503px);
    overflow-y: auto;
  }

  .event-live-cls-add .text-portion h6 {
    font-size: 12px !important;
    margin-bottom: 0px;
  }

  .event-live-cls-add .avatar-mainimg img.avatar-gym-main-story {
    width: 37px;
    height: 37px;
  }

  .event-live-cls-add .pills-new-button-record-design button {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .event-live-cls-add .views-story-scroll {
    max-height: 390px !important;
  }

  .healthcare-box h6 {
    white-space: break-spaces !important;
    font-size: 10px !important;
  }

  .spacing-box svg {
    width: 18px !important;
    height: 18px !important;
    margin-right: 6px !important;
  }

  .main-custom-inner .main-story .new_story_modal_add .top-portion {
    margin: 21px 0px 0 19px !important;
  }

  #view-responsive-progress .main-custom-inner .main-story .new_story_modal_add .music-portion-main {
    margin: 28px auto 50px auto !important;
  }

  .feed-coomon-cls .music-portion-main .skip-now-btn {
    white-space: nowrap;
  }

  .body_middle_content_Section .middle_left_section .feed_box_wraper .feed_middle_content_section .feed-coomon-cls .music-portion-main .progress-bar-portion {
    width: 79% !important;
  }
}

.all-img-video-cls .modal-content {
  max-width: 550px !important;
  width: 550px !important;
}

.only-vide-cls-add {
  position: absolute;
  left: 44%;
  right: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
}

.only-vide-cls-add-left {
  position: absolute;
  top: 53%;
  transform: translate(-50%, -50%);
  margin: 0 0px 0 90px;
}

.all-img-video-cls .gym-imgs.video-js {
  max-height: 400px;
  height: 400px;
}

.all-img-video-cls .gym-imgs.video-js video {
  object-fit: contain !important;
}

.gym-imgs.video-js video {
  object-fit: contain !important;
}

video#myVideo {
  object-fit: contain;
}

.upload-all.center-position-cls-add img.avatar-gym-main-story {
  margin: 0 auto !important;
  text-align: center;
  display: block;
}

.position-change-cls {
  position: absolute;
  left: 0;
  top: 1%;
  width: 100%;
}

.loading-wrapeer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1111;
  background: rgba(0, 0, 0, 0.5);
}

.loading-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-wrapeer .spinner-border.text-primary {
  color: #ff1b00 !important;
}

.loading-wrapeer p {
  font-size: 23px;
  font-weight: 700;
  color: #fff;
}

.view-story.photos-img {
  text-align: center;
}

.final-change-responsive .upload-img-channel.fanchannel-story {
  width: 80px !important;
  height: 80px !important;
}

.final-change-responsive .profile-pic {
  width: 80px !important;
  height: 80px !important;
}

.only-chat-portion-css-apply {
  height: calc(100vh - 606px);
  overflow-y: auto;
}

.event_main_section {
  height: calc(100vh - 226px);
}

.feed_middle_content_section .cursor-pointer .video_section img {
  margin-right: 13px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 5px;
}

.watermark img {
  text-align: end;
  display: block;
  margin-top: 158%;
  margin-right: 20px;
  margin-left: auto;
}

span.set-position-open {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0px 11px 2px 0px;
}

span.set-position-open img.watermark-image-show {
  width: 30px !important;
  height: 30px !important;
  opacity: 0.7;
}

.main-custom-post-img-cls .unique-img-pad-decrease {
  padding-right: 5px !important;
  padding-bottom: initial !important;
}

.unique-img-pad-decrease img {
  margin-bottom: 5px;
}

.new-modal-comment-openbox-cls {
  .modal-content {
    max-width: 603px !important;
  }
}

.new-modal-comment-openbox-cls .image-cropper {
  width: 500px !important;
  height: 150px !important;
}


.new-modal-comment-openbox-cls mention-list {
  top: initial !important;
  margin: -31px 0 0 78px;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";


.workout-main-section {
  padding: 17px 0;

  .plan-a-day-row {
    text-align: center;

    .plan-a-day {
      width: 95%;
      display: inline-block;
      text-align: center;


      .carousel {
        display: flex;
        justify-content: center;

        .carousel-inner {
          width: 96%;

          .carousel-item {
            text-align: center;
            border: 1px solid #C7C7C7;
            border-radius: 10px;
            margin: 0 6.5px;
            padding: 13px;
            cursor: pointer;

            span {
              font-size: 18px;
              color: #000;
              font-weight: 600;
            }

            label {
              font-size: 12px;
              color: #666666;
              font-weight: 500;
            }

            &.current-date-col {
              background: rgb(255, 178, 0);
              background: linear-gradient(216deg, rgba(255, 178, 0, 1) 0%, rgba(252, 60, 37, 1) 100%);
              border: 1px solid transparent;

              span {
                color: #fff;
              }

              label {
                color: #fff;
              }


            }

          }

        }
      }

      .carousel-control-next,
      .carousel-control-prev {
        width: inherit !important;
        opacity: inherit !important;
      }

      .carousel-control-next {
        right: -5px;
      }

      .carousel-control-prev {
        left: -5px;
      }

      .carousel-control-prev-icon {
        border: solid black;
        border-width: 0 3px 3px 0;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        background: none;
        width: 1rem;
        height: 1rem;
      }

      .carousel-control-next-icon {
        border: solid black;
        border-width: 0 3px 3px 0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        background: none;
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.workout-dtl {
  margin-top: 155px;

  p {
    margin: 19px 0;
    font-size: 14px;
    font-weight: 500;
    color: #666666;
  }

}

.workout-dtl-row {
  margin-top: 50px;
  padding: 0 45px;


  .carousel-inner {
    .carousel-item {
      margin: 0 11px;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: inherit !important;
    opacity: inherit !important;
  }

  .carousel-control-next {
    right: -20px;
  }

  .carousel-control-prev {
    left: -20px;
  }

  .carousel-control-prev-icon {
    border: solid black;
    border-width: 0 3px 3px 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    background: none;
    width: 1rem;
    height: 1rem;
  }

  .carousel-control-next-icon {
    border: solid black;
    border-width: 0 3px 3px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    background: none;
    width: 1rem;
    height: 1rem;
  }

  .workout-dtl-col {
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    float: left;
    overflow: overlay;
    max-height: 560px;

    .workout-dtl-title {
      display: flex;
      justify-content: space-between;

      h6 {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        line-height: 23px;
      }

      p {
        font-size: 14px;
        color: #666666;
        font-weight: 600;
        line-height: 20px;
      }

      .w-dtitle-right {
        position: relative;

        p {
          padding: 0 7px;
          cursor: pointer;
        }

        .more-action {
          position: absolute;
          right: 0;
          background: #fff;
          border: 1px solid #ccc;
          padding: 19px;
          border-radius: 10px;
          min-width: 200px;
          z-index: 111111;
          top: 20px;
          inset: 50% auto auto -145px !important;

          ul {
            margin: 0;
            padding: 0;

            li {
              padding-bottom: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #000000;

              &:last-child {
                padding-bottom: 0;
              }

              a {
                text-decoration: none;
                color: inherit;
              }
            }
          }
        }

      }
    }

    .exercises-listing {
      h5 {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
      }

      .exercises-listing-col {
        background-color: #F5F5F5;
        border-radius: 4px;
        margin-top: 12px;
        width: 100%;
        float: left;
        margin-bottom: 10px;
        border-radius: 4px;
        display: flex;

        &:last-child {
          margin-bottom: 0;
        }

        .exercises-img {
          width: 72px;
          height: 60px;
          float: left;

          img {
            border-radius: 4px 0px 0px 4px;
            width: 100%;
            overflow: hidden;
            height: inherit;
          }
        }

        .exercises-dtl {
          width: calc(100% - 87px);
          float: right;
          padding-top: 8px;
          padding-left: 10px;

          h5 {
            font-size: 14px;
            font-weight: 500;
            color: #000000;
          }

          p {
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            margin: 0;
          }
        }

        .button {
          padding-top: 18px;
          margin-right: 15px;

          img {
            width: 25px;
          }
        }
      }
    }
  }
}

.background-dark {
  background-color: rgba(0, 0, 0, 0.5);
}