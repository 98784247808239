@font-face {
    font-family: "PoppinsBlack";
    src: url("/assets/fonts/Poppins/Poppins-Black.eot");
    src: url("/assets/fonts/Poppins/Poppins-Black.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-Black.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-Black.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-Black.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-Black.svg#Poppins-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsExtraBold";
    src: url("/assets/fonts/Poppins/Poppins-ExtraBold.eot");
    src: url("/assets/fonts/Poppins/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-ExtraBold.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-ExtraBold.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-ExtraBold.svg#Poppins-ExtraBold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("/assets/fonts/Poppins/Poppins-Bold.eot");
    src: url("/assets/fonts/Poppins/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-Bold.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-Bold.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-Bold.svg#Poppins-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsExtraLight";
    src: url("/assets/fonts/Poppins/Poppins-ExtraLight.eot");
    src: url("/assets/fonts/Poppins/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-ExtraLight.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-ExtraLight.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-ExtraLight.svg#Poppins-ExtraLight") format("svg");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsMedium";
    src: url("/assets/fonts/Poppins/Poppins-Medium.eot");
    src: url("/assets/fonts/Poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-Medium.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-Medium.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-Medium.svg#Poppins-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsLight";
    src: url("/assets/fonts/Poppins/Poppins-Light.eot");
    src: url("/assets/fonts/Poppins/Poppins-Light.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-Light.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-Light.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-Light.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-Light.svg#Poppins-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsRegular";
    src: url("/assets/fonts/Poppins/Poppins-Regular.eot");
    src: url("/assets/fonts/Poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-Regular.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-Regular.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-Regular.svg#Poppins-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("/assets/fonts/Poppins/Poppins-SemiBold.eot");
    src: url("/assets/fonts/Poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-SemiBold.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsThin";
    src: url("/assets/fonts/Poppins/Poppins-Thin.eot");
    src: url("/assets/fonts/Poppins/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Poppins/Poppins-Thin.woff2") format("woff2"),
        url("/assets/fonts/Poppins/Poppins-Thin.woff") format("woff"),
        url("/assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype"),
        url("/assets/fonts/Poppins/Poppins-Thin.svg#Poppins-Thin") format("svg");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RubikBold";
    src: url("/assets/fonts/Rubik/Rubik-Bold.eot");
    src: url("/assets/fonts/Rubik/Rubik-Bold.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Rubik/Rubik-Bold.woff2") format("woff2"),
        url("/assets/fonts/Rubik/Rubik-Bold.woff") format("woff"),
        url("/assets/fonts/Rubik/Rubik-Bold.ttf") format("truetype"),
        url("/assets/fonts/Rubik/Rubik-Bold.svg#Rubik-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RubikExtraBold";
    src: url("/assets/fonts/Rubik/Rubik-ExtraBold.eot");
    src: url("/assets/fonts/Rubik/Rubik-ExtraBold.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Rubik/Rubik-ExtraBold.woff2") format("woff2"),
        url("/assets/fonts/Rubik/Rubik-ExtraBold.woff") format("woff"),
        url("/assets/fonts/Rubik/Rubik-ExtraBold.ttf") format("truetype"),
        url("/assets/fonts/Rubik/Rubik-ExtraBold.svg#Rubik-ExtraBold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RubikBlack";
    src: url("/assets/fonts/Rubik/Rubik-Black.eot");
    src: url("/assets/fonts/Rubik/Rubik-Black.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Rubik/Rubik-Black.woff2") format("woff2"),
        url("/assets/fonts/Rubik/Rubik-Black.woff") format("woff"),
        url("/assets/fonts/Rubik/Rubik-Black.ttf") format("truetype"),
        url("/assets/fonts/Rubik/Rubik-Black.svg#Rubik-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RubikLight";
    src: url("/assets/fonts/Rubik/Rubik-Light.eot");
    src: url("/assets/fonts/Rubik/Rubik-Light.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Rubik/Rubik-Light.woff2") format("woff2"),
        url("/assets/fonts/Rubik/Rubik-Light.woff") format("woff"),
        url("/assets/fonts/Rubik/Rubik-Light.ttf") format("truetype"),
        url("/assets/fonts/Rubik/Rubik-Light.svg#Rubik-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RubikRegular";
    src: url("/assets/fonts/Rubik/Rubik-Regular.eot");
    src: url("/assets/fonts/Rubik/Rubik-Regular.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Rubik/Rubik-Regular.woff2") format("woff2"),
        url("/assets/fonts/Rubik/Rubik-Regular.woff") format("woff"),
        url("/assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype"),
        url("/assets/fonts/Rubik/Rubik-Regular.svg#Rubik-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RubikMedium";
    src: url("/assets/fonts/Rubik/Rubik-Medium.eot");
    src: url("/assets/fonts/Rubik/Rubik-Medium.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Rubik/Rubik-Medium.woff2") format("woff2"),
        url("/assets/fonts/Rubik/Rubik-Medium.woff") format("woff"),
        url("/assets/fonts/Rubik/Rubik-Medium.ttf") format("truetype"),
        url("/assets/fonts/Rubik/Rubik-Medium.svg#Rubik-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "RubikSemiBold";
    src: url("/assets/fonts/Rubik/Rubik-SemiBold.eot");
    src: url("/assets/fonts/Rubik/Rubik-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("/assets/fonts/Rubik/Rubik-SemiBold.woff2") format("woff2"),
        url("/assets/fonts/Rubik/Rubik-SemiBold.woff") format("woff"),
        url("/assets/fonts/Rubik/Rubik-SemiBold.ttf") format("truetype"),
        url("/assets/fonts/Rubik/Rubik-SemiBold.svg#Rubik-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

// Font Variable

$PoppinsBlack: "PoppinsBlack";
$PoppinsExtraBold: "PoppinsExtraBold";
$PoppinsBold: "PoppinsBold";
$PoppinsExtraLight: "PoppinsExtraLight";
$PoppinsMedium: "PoppinsMedium";
$PoppinsLight: "PoppinsLight";
$PoppinsRegular: "PoppinsRegular";
$PoppinsSemiBold: "PoppinsSemiBold";
$PoppinsThin: "PoppinsThin";
$RubikBold: "RubikBold";
$RubikExtraBold: "RubikExtraBold";
$RubikBlack: "RubikBlack";
$RubikLight: "RubikLight";
$RubikRegular: "RubikRegular";
$RubikMedium: "RubikMedium";
$RubikSemiBold: "RubikSemiBold";

/*=== Font Size ===*/

$msg_count_txt: 8px;
$search_txt: 10px;
$detail_txt: 12px;
$welcome_txt_size: 13px;
$btn_txt: 14px;
$user_txt: 16px;
$event_date_txt: 18px;
$plan_ttl: 22px;
$welcome_main_ttl: 25px;
$modal_heading: 26px;
$main_ttl: 30px;
$e_wallet_price_txt: 35px;

/*=== Font Color ===*/

$white: #fff;
$black: #000;
$welcome_txt_color: #666666;
$welcome_txt_red: #ff1b00;
$welcome_txt_yellow: #ffb200;
$form_control_bg: #f5f5f5;
$form_control_border: #c7c7c780;
$form_control_placeholder: #999999;
$checkbox_border: #c7c7c7;
$btn_bg: linear-gradient(210deg, rgba(255, 178, 0, 1) 0%, rgba(255, 27, 0, 1) 100%);
$sidebar_Active_link_color: linear-gradient(350deg, #ffb200 0%, #ff1b00 100%);
$msg_count_bg: transparent linear-gradient(180deg, #ff1b00 0%, #ffb200 100%) 0% 0% no-repeat padding-box;
$fill_btn_bg: transparent linear-gradient(190deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
$post_btn_bg: transparent linear-gradient(192deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
$tab_active_bg: transparent linear-gradient(260deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
$plan_bg_one: transparent linear-gradient(114deg, #eb8a40 0%, #d15293 100%) 0% 0% no-repeat padding-box;
$plan_bg_two: transparent linear-gradient(113deg, #62b9f6 0%, #8519fa 100%) 0% 0% no-repeat padding-box;
$plan_bg_three: transparent linear-gradient(113deg, #9235f7 0%, #b10edb 100%) 0% 0% no-repeat padding-box;
$icon_bg: transparent linear-gradient(180deg, #ff2200 0%, #ffa400 100%) 0% 0% no-repeat padding-box;
$nav_shadow_color: #0000000d;
$sidebar_txt_color: #333333;
$sidebar_shadow_color: #0000000f;
$link_color: #2779fd;
$user_deactive_status: #fbb947;
$user_active_status: #2ace76;
$event_Color_first: #fff0e5;
$event_Color_second: #e5f7ff;
$event_Color_third: #ffe7ed;
$light_fill_bg: #feebeb;
$following_btn_bg: #ff2200;
$user_name_txt: #181818;
$radio_txt: #222222;
$pending_status_color: #ffa400;
$inprogress_status: #f66f07;
$date_bg: #f1f1f1;
$date_txt_color: #acacac;
$gray_txt: #5f6368;
$border_color: #ededed;
$box_shadow_color: #0000001a;
$cal_light_color: #919191;
$chat_border_color: #f1f1f1;
$chat_received_bg: #fff3d6;
$avatar_border_bg: linear-gradient(to right, #ff1b00, #ff9a00, #ff9a00);
$paragraph_heading_color: #e1e1e1;
$progress_bar_color: transparent linear-gradient(264deg, #ffb200 0%, #ff1b00 100%) 0% 0% no-repeat padding-box;
$ics_plus_icon: transparent linear-gradient(190deg, #ffb200 0%, $welcome_txt_red 100%) 0% 0% no-repeat padding-box;
$tips_btn: transparent linear-gradient(211deg, #ffa400 0%, #ff2200 100%) 0% 0%;
$online-chat-green-dot: #2ace76;
$online-chat-orange-dot: #fbb947;
$heart-rate: #ffe9cc;
$step: #c8ebff;
$spo: #d8efda;
$bloodpressure: #f1dbe9;
$sleep: #ffdfd1;
$water: #dfe5fe;
